<template>
    <div class="modal_wrap dp_flex flex_justify_center align_center">
        <div class="vocal_modal dp_flex flex_column">
            <ul class="dp_flex flex_space_between align_center">
                <li>
                    <h3 class="vocal_modal_head_title">{{ generobject.genrename }} 보컬 유무를 선택해주세요.</h3>
                </li>
                <li>
                    <img
                        class="curation_close cursor"
                        @click="$emit('close-vocalmodal')"
                        src="/media/img/curation_close.png"
                        alt="닫기"
                    />
                </li>
            </ul>
            <ul class="dp_flex flex_space_between vocal_select">
                <li>
                    <button class="vocal_select_btn" :class="{ active: selectValue === 0 }" @click="onClickGenre(0)">
                        보컬 있음
                    </button>
                </li>
                <li>
                    <button class="vocal_select_btn" :class="{ active: selectValue === 1 }" @click="onClickGenre(1)">
                        보컬 없음
                    </button>
                </li>
                <li>
                    <button class="vocal_select_btn" :class="{ active: selectValue === 2 }" @click="onClickGenre(2)">
                        상관 없음
                    </button>
                </li>
            </ul>
            <ul class="flex_end vocal_select_footer">
                <li>
                    <button class="vocal_btn" @click="$emit('close-vocalmodal')">취소</button>
                </li>
                <li>
                    <button class="vocal_btn confirm" @click="onClickConfirm">적용</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  name: 'VocalModal',
  emits: ['close-vocalmodal', 'confirm-vocal'],
  props: {
    generobject: {
      type: Object,
      required: false,
      default: () => {
        return {
          genrename: '재즈',
          genrevalue: 5,
          selectvalue: -1
        };
      }
    }
  },
  data () {
    return {
      selectValue: -1
    };
  },
  created () {
    this.selectValue = this.generobject.selectvalue;
  },

  mounted () {},

  methods: {
    onClickGenre (val) {
      if (val === this.selectValue) {
        this.selectValue = -1;
      } else {
        this.selectValue = val;
      }
    },
    onClickConfirm () {
      this.$emit('confirm-vocal', {
        genrevalue: this.generobject.genrevalue,
        selectVocalValue: this.selectValue
      });
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/curation/vocalmodal.css"></style>
